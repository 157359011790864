<template>
  <div class="score-item">
     <img v-if="score.scoreImageList[0]" :src="score.scoreImageList[0].file.url" alt="" />
     <p class="name ellipsis-2">{{score.name}}-{{score.author}}</p>
     <p class="desc">{{score.scoreType.type}}-{{score.user.nickname}}</p>
     <!-- <p class="price">&yen;{{goods.price}}</p> -->
    <div class="extra">
      <RouterLink :to="'/piano/'+score.id"><span><i class="iconfont icon-start"></i>&nbsp;开始弹奏</span></RouterLink>
      <span v-if="!isCollect" class="collect" @click="collectClick"><i class="iconfont icon-collect"></i>&nbsp;收藏</span>
      <span v-else class="collect" @click="cancelClick"><i class="iconfont icon-collect"></i>&nbsp;取消收藏</span>
    </div>
  </div>
</template>

<script>
//import {useStore} from 'vuex'
//import {ref} from 'vue'
//import {useRouter} from 'vue-router'
//import {addCollect,deleteCollect} from '../../../api/collect'
import Message from '../../../components/library/Message'
export default {
  name: 'Score',
  props: {
    score: {
      type: Object,
      default: () => {}
    },
    isCollect:{
      type:Boolean,
      default:false
    }
  },
  setup(props,{emit}){
    // const isCollect=ref(false);
    //const store=useStore();
    //const router=useRouter();

    const collectClick=()=>{
      if(props.isCollect){
        return;
      }
      // console.log(store.state.user.loginUser);
      if(props.score.id<0){
        Message({type:"error",text:"收藏失败，请稍后再试。"})
        return;
      }
      emit("collect");
      // let collect={userId:,scoreId:props.score.id};
      // addCollect(collect).then((res)=>{
      //   if(res.data=='success'){
      //     Message({type:'success',text:'收藏成功'});
      //     isCollect.value=true;
      //   }
      // }).catch(()=>{
      //   Message({type:'error',text:'收藏失败'});
      // })
    }

    const cancelClick=()=>{
      if(props.isCollect==false){
        return;
      }
      if(props.score.id<0){
        Message({type:"error",text:"取消失败，请稍后再试。"})
        return;
      }
      emit("cancelCollect");
      // let collect={userId:store.state.user.loginUser.id,scoreId:props.score.id};
      // deleteCollect(collect).then((res)=>{
      //   if(res.data=='success'){
      //     Message({type:'success',text:'已取消收藏'});
      //     isCollect.value=false;
      //   }
      // }).catch(()=>{
      //   Message({type:'error',text:'取消失败'});
      // })
    }

    // const judgeIsCollect=()=>{
    //   for(let collect of store.state.collect.collects){
    //     if(collect.score.id==props.score.id){
    //       return true;
    //     }
    //   }
    //   return false;
    // }
    // watch(()=>{return store.state.collect.collects},()=>{
    //   isCollect.value=judgeIsCollect();
    // },{immediate:true})
    return {collectClick,cancelClick};
  }
}
</script>

<style scoped lang='less'>
//@import "../../../assets/styles/variables.less";
.score-item {
  //border: solid;
  width: 240px;
  height: 330px;
  padding: 5px 20px;
  position: relative;
  overflow: hidden;
  border: 0.5px solid;
  transition: all .5s;
  img{
    width: 100%;
    height: 250px;
  }
  // .image {
  //   display: block;
  //   width: 160px;
  //   height: 160px;
  //   margin: 0 auto;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
  p {
    margin-top: 6px;
    font-size: 16px;
    &.name {
      height: 25px;
      //border: solid;
      text-align: center;
    }
    &.desc {
      color: black;
      height: 20px;
      text-align: center;
      //border: solid;
    }
  }
  .extra {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 86px;
    width: 100%;
    background: black;
    text-align: center;
    transform: translate3d(0,100%,0);
    transition: all .5s;
    span {
      display: block;
      color: #fff;
      width: 120px;
      margin: 0 auto;
      line-height: 30px;
      &:first-child {
        font-size: 18px;
        border-bottom:1px solid #fff;
        line-height: 40px;
        margin-top: 5px;
      }
    }
  }
  &:hover {
    border: solid 1px;
    border-color: black;
    .hoverShadow();
    .extra {
      transform: none;
    }
  }
  .collect{
    &:hover {
      cursor: pointer;
    }
  }
}
</style>