<template>
    <div class='myCollect'>
       <ul class="score-list">
        <li v-for="(item,index) of collectList" :key="item.id">
            <Score :score="item.score" :isCollect="true" @cancelCollect="cancelCollect(index)"/>
        </li>
      </ul>
    </div>
</template>

<script>
//import {useStore} from 'vuex'
import {ref} from 'vue'
import Score from '../../../home/components/score.vue'
import axios from "../../../../utils/request";
import Message from '../../../../components/library/Message';
import qs from "qs";
export default {
  name: 'myCollect',
  components:{
    Score
  },
  setup(){
    //const store=useStore();
    const collectList=ref(null);
    //scores.value=store.state.collect.collects;
    //store.dispatch('collect/updateCollects');
    //let user=JSON.parse(localStorage.getItem("user"));
    axios.get("/collect/getCollectList").then((res)=>{
      collectList.value=res;
    }).catch((err)=>{
      Message({type:"error",error:err.message});
    })

    const cancelCollect=(index)=>{
      axios.post("/collect/deleteCollectById",qs.stringify({id:collectList.value[index].id})).then(()=>{
        collectList.value.splice(index,1);
        Message({type:"success",text:"取消成功"});
      }).catch((err)=>{
        Message({type:"error",text:err.message});
      })
    }
   
    // watch(()=>{return store.state.collect.collects},(newVal)=>{
    //   scores.value=newVal;
    //   console.log("sc");
    //   console.log(scores.value);
    // },{immediate:true})
    return {collectList,cancelCollect};
  }
}
</script>

<style scoped lang='less'>
.score-list {
    margin: auto;
    width: 950px;
    //border: solid;
    //width: 990px;
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-around;
    li {
        width: 240px;
        //height: 350px;
        margin-right: 40px;
        margin-bottom: 10px;
        &:nth-last-child(-n+4) {
            margin-bottom: 0;
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}
</style>